import Axios, { AxiosError, AxiosResponse } from 'axios';
import { ALERT_LIMIT, ALERTS_PAGE_LIMIT, getAPIUrl, HEARTBEAT_WARNING_LIMIT } from 'src/config';
import IAlert from 'src/ServerEntities/IAlert';
import IAlertDetails from 'src/ServerEntities/IAlertDetails';
import IChannelCode from 'src/ServerEntities/IChannelCode';
import ICombinedSiteSupportIssues from 'src/ServerEntities/ICombinedSiteSupportIssues';
import IDashboardInfo from 'src/ServerEntities/IDashboardInfo';
import IErrorDetails from 'src/ServerEntities/IErrorDetails';
import IHeaderInformation from 'src/ServerEntities/IHeaderInformation';
import IHybridView from 'src/ServerEntities/IHybridView';
import INote from 'src/ServerEntities/INote';
import IRhapsodyAlert from 'src/ServerEntities/IRhapsodyAlert';
import IRhapsodyCertificate from 'src/ServerEntities/IRhapsodyCertificate';
import IRhapsodyComponents from 'src/ServerEntities/IRhapsodyComponents';
import IRhapsodyDashboard from 'src/ServerEntities/IRhapsodyDashboard';
import IRhapsodyMessageCounts from 'src/ServerEntities/IRhapsodyMessageCounts';
import IRhapsodyQueues from 'src/ServerEntities/IRhapsodyQueues';
import IRhapsodyServer from 'src/ServerEntities/IRhapsodyServer';
import IRhapsodyServerPanel from 'src/ServerEntities/IRhapsodyServerPanel';
import IRhapsodyUser from 'src/ServerEntities/IRhapsodyUser';
import IRoleSite from 'src/ServerEntities/IRoleSite';
import IServerAlerts from 'src/ServerEntities/IServerAlerts';
import IServerInformation from 'src/ServerEntities/IServerInformation';
import ISite from 'src/ServerEntities/ISite';
import ISupportIssueRequest from 'src/ServerEntities/ISupportIssueRequest';
import { NETWORK_ERROR } from 'src/UsefulFunctions/createErrorMessage';

export const getOverview = (webToken: string, successCallback: (sites: ISite[]) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/sites`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<ISite[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getHybridView = (webToken: string, successCallback: (view: IHybridView) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/hybrid?pageLimit=${ALERTS_PAGE_LIMIT}&offset=0`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IHybridView>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getSiteHybridView = (site: string, webToken: string, successCallback: (sites: IHybridView) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/hybrid?site=${site}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IHybridView>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getServer = (site: string, serverName: string, namespace: string, webToken: string, successCallback: (server: IServerInformation) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/sites/${site}/servers/${serverName}/${namespace}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IServerInformation>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const saveServerNote = (site: string, serverName: string, namespace: string, note: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    Axios.post(
        `${getAPIUrl()}ram/sites/${site}/servers/${serverName}/${namespace}/note`,
        note,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "text/plain" } }
    )
        .then(successCallback)
        .catch((error: AxiosError<IErrorDetails>) => {
            failureCallback(workoutErrorMessage(error));
        });
};

export const getSites = (webToken: string, successCallback: (sites: IRoleSite[]) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/sites/names`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IRoleSite[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getCurrentAlerts = (webToken: string, offset: number, successCallback: (alerts: IServerAlerts) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/alerts/current?pageLimit=${ALERTS_PAGE_LIMIT}&offset=${offset}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IServerAlerts>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getClosedAlerts = (webToken: string, offset: number, successCallback: (alerts: IServerAlerts) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/alerts/closed?alertLimit=${ALERT_LIMIT}&pageLimit=${ALERTS_PAGE_LIMIT}&offset=${offset}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IServerAlerts>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getAlertDetails = (id: number, webToken: string, successCallback: (alertDetails: IAlertDetails) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/alert/${id}/details`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IAlertDetails>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const setAlertClosed = (id: number, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    Axios.put(
        `${getAPIUrl()}ram/alert/${id}/close`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    ).then((response: AxiosResponse<any>) => {
        successCallback();
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const closeAllAlertsBySite = (siteName: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    Axios.put(
        `${getAPIUrl()}ram/alerts/${siteName}/close/all`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    ).then((response: AxiosResponse<any>) => {
        successCallback();
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getHeaderInformation = (webToken: string, successCallback: (headerInformation: IHeaderInformation) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/header?heartbeatLimit=${HEARTBEAT_WARNING_LIMIT}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IHeaderInformation>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getAlertsBySite = (site: string, webToken: string, successCallback: (alerts: IAlert[]) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/site/${site}/alerts?alertLimit=${ALERT_LIMIT}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IAlert[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getNotes = (alertId: number, webToken: string, successCallback: (notes: INote[]) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/alert/${alertId}/notes`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<INote[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const addNote = (alertId: number, note: string, webToken: string, successCallback: (response: string) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.post(
        `${getAPIUrl()}ram/alert/${alertId}/notes`,
        note,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "text/plain" } }
    ).then((response: AxiosResponse<string>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const updateSiteDescription = (site: string, description: string, webToken: string, successCallback: (response: string) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.put(
        `${getAPIUrl()}ram/sites/${site}/description`,
        description,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<string>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};


export const silenceServer = (server: string, webToken: string, successCallback: (response: string) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.post(
        `${getAPIUrl()}ram/servers/${server}/silence`,
        "",
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<string>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const updateAlertMessageId = (alertId: number, messageId: string, webToken: string, successCallback: (response: string) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.post(
        `${getAPIUrl()}ram/alert/${alertId}/messageId`,
        messageId,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<string>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const updateAlertTicket = (alertId: number, ticket: string, webToken: string, successCallback: (response: string) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.post(
        `${getAPIUrl()}ram/alert/${alertId}/ticket`,
        ticket,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<string>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getCanCreateSupportIssue = (webToken: string, successCallback: (canCreate: boolean) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/issues/permission`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    ).then((response: AxiosResponse<boolean>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getSupportIssues = (webToken: string, successCallback: (issues: ICombinedSiteSupportIssues) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/issues`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<ICombinedSiteSupportIssues>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const saveSupportIssue = (issue: ISupportIssueRequest, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    Axios.post(
        `${getAPIUrl()}ram/issues/`,
        issue,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<any>) => {
        successCallback();
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const setIssueClosed = (id: number, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    Axios.put(
        `${getAPIUrl()}ram/issues/${id}/close`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    ).then((response: AxiosResponse<any>) => {
        successCallback();
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const assignIssue = (id: number, username: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    Axios.put(
        `${getAPIUrl()}ram/issues/${id}/assign`,
        username,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "text/plain" } }
    ).then((response: AxiosResponse<any>) => {
        successCallback();
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getIssueNotes = (issueId: number, webToken: string, successCallback: (notes: INote[]) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/issues/${issueId}/notes`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<INote[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const addIssueNote = (issueId: number, note: string, webToken: string, successCallback: (response: string) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.post(
        `${getAPIUrl()}ram/issues/${issueId}/notes`,
        note,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "text/plain" } }
    ).then((response: AxiosResponse<string>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getDashboardInfo = (webToken: string, successCallback: (dashboardInfo: IDashboardInfo) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}client/dashboard`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IDashboardInfo>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getDashboardInfoByClientId = (clientId: number, webToken: string, successCallback: (dashboardInfo: IDashboardInfo) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}client/${clientId}/dashboard`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IDashboardInfo>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

const workoutErrorMessage = (error: AxiosError<IErrorDetails>) => {
    let errorMessage = "Unknown error";
    if (error.response) {
        if (error.response.status === 409) {
            errorMessage = "List already exists";
        } else {
            errorMessage = error.response.data.message
        }
    }
    if (error.message === "Network Error") {
        errorMessage = NETWORK_ERROR;
    }
    return errorMessage as string
};

export const updateAlertMuted = (alertId: number, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    Axios.post(
        `${getAPIUrl()}ram/alerts/${alertId}/mute`, {},
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<string>) => {
        successCallback();
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const updateAlertPriority = (alertId: number, priority: number, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    Axios.put(
        `${getAPIUrl()}ram/alerts/${alertId}/priority/${priority}`, {},
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<string>) => {
        successCallback();
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    })
};

export const getChannelCodes = (webToken: string, successCallback: (codes: IChannelCode[]) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/channelcodes/`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IChannelCode[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const saveChannelCode = (channelCode: IChannelCode, webToken: string, successCallback: (codes: IChannelCode[]) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.post(
        `${getAPIUrl()}ram/channelcodes/`, channelCode,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IChannelCode[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const deleteChannelCode = (channel: string, webToken: string, successCallback: (codes: IChannelCode[]) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.delete(
        `${getAPIUrl()}ram/channelcodes/${channel}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IChannelCode[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getRhapsodyHeader = (webToken: string, successCallback: (rhapsodyServers: IRhapsodyServer[]) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/rhapsody/header`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IRhapsodyServer[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getRhapsodyComponents = (serverId: number, webToken: string, successCallback: (components: IRhapsodyComponents) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/rhapsody/servers/${serverId}/components`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IRhapsodyComponents>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getRhapsodyDashboard = (serverId: number, webToken: string, successCallback: (dashboard: IRhapsodyDashboard) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/rhapsody/servers/${serverId}/dashboard`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IRhapsodyDashboard>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getRhapsodyServers = (webToken: string, successCallback: (rhapsodyServers: IRhapsodyServerPanel[]) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/rhapsody/servers`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IRhapsodyServerPanel[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getRhapsodyAlerts = (webToken: string, successCallback: (alerts: IRhapsodyAlert[]) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/rhapsody/alerts`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IRhapsodyAlert[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getRhapsodyCertificates = (webToken: string, successCallback: (rhapsodyServers: IRhapsodyCertificate[]) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/rhapsody/certificates`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IRhapsodyCertificate[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getRhapsodyQueues = (webToken: string, successCallback: (rhapsodyServers: IRhapsodyQueues) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/rhapsody/queues`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IRhapsodyQueues>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getRhapsodyMessages = (webToken: string, successCallback: (messages: IRhapsodyMessageCounts[]) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/rhapsody/messages`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IRhapsodyMessageCounts[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getRhapsodyUsers = (webToken: string, successCallback: (users: IRhapsodyUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    Axios.get(
        `${getAPIUrl()}ram/rhapsody/users`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IRhapsodyUser[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};
